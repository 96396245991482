<template>
  <div class="Output-Model">
    <h3 class="mb-4">{{ title }}</h3>
    <div
      v-if="exampleOutput && exampleOutput.length"
      class="Output-Model__Output mb-4">
      <v-treeview
        dense
        open-on-click
        :items="exampleOutput">
        <template v-slot:prepend="{ item }">
          <span class="Output-Model__Output__name">{{ item.name }}</span>
        </template>
        <template v-slot:label="{ item }">
          <span class="Output-Model__Output__type">{{ item.type }}</span>
        </template>
      </v-treeview>
    </div>
    <p
      v-else
      class="text--secondary subtitle-2 mb-4">
      No output
    </p>
  </div>
</template>

<script>
export default {
  name: 'OutputModel',
  props: {
    model: { default: null },
    title: { default: 'Output model' },
  },
  computed: {
    exampleOutput() {
      return this.getItems(this.model);
    },
  },
  methods: {
    getItems(output) {
      if (Array.isArray(output)) {
        return output.map((i) => {
          return {
            id: i.uid,
            name: `${i.name}: `,
            type: i.type,
            children: this.getItems(i.value),
          };
        });
      } else if (output != null && typeof output === 'object') {
        return [
          {
            id: output.uid,
            name: `${output.name}: `,
            type: output.type,
            children: this.getItems(output.value),
          },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './output-model';
</style>
