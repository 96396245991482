<template>
  <v-menu offset-y :max-height="height ?? 400" v-model="showDropdown">
    <template v-slot:activator="{}">
      <div ref="editorContainer" class="monaco_editor_container">
        <div :id="placeHolderId" class="monaco-placeholder">
          <pre>
            <code>
              {{ placeholder }}
            </code>
          </pre>
        </div>
      </div>
    </template>
  </v-menu>
</template>

<script>
import loader from '@monaco-editor/loader';

export default {
  name: 'MonacoEditor',
  data() {
    return {
      theme: 'vs-dark',
      editor: null,
      placeHolderId: 'MONACO_PLACEHOLDER_CONTAINER',
      options: {},
      showDropdown: false,
      event: null,
    };
  },
  props: {
    value: String,
    language: { type: String, required: true },
    placeholder: { type: String, required: true },
    suggestions: { required: false },
    height: { type: String, required: false },
  },
  watch: {
    value() {
      this.editor &&
        this.value !== this._getValue() &&
        this._setValue(this.value);
    },
  },
  async mounted() {
    loader.init().then((monaco) => {
      const editorOptions = {
        value: this.value,
        language: this.language,
        theme: this.theme,
        automaticLayout: true,
        tabSize: 2,
      };

      this._registerAutocompletion(monaco);
      this.editor = monaco.editor.create(
        this.$refs.editorContainer,
        editorOptions
      );

      this.editor.onDidBlurEditorWidget(() => {
        this.showPlaceholder();
      });

      this.editor.onDidFocusEditorWidget(() => {
        this.hidePlaceholder();
      });
      this._editorMounted(this.editor);
    });
  },
  beforeDestroy() {
    this.editor && this.editor.dispose();
  },
  methods: {
    _getEditor() {
      if (!this.editor) return null;
      return this.editor;
    },
    _setValue(value) {
      let editor = this._getEditor();
      if (editor) return editor.setValue(value);
    },
    _getValue() {
      let editor = this._getEditor();
      if (!editor) return '';
      return editor.getValue();
    },
    _editorMounted(editor) {
      editor.onDidChangeModelContent((event) => {
        const value = this._getValue();
        this._emitChange(value, event);
      });
    },
    _emitChange(value, event) {
      this.$emit('change', value, event);
      this.$emit('input', value);
    },
    _registerAutocompletion(monaco) {
      const languages = ['javascript', 'sql', 'python'];
      languages.forEach((language) => {
        monaco.languages.registerCompletionItemProvider(language, {
          triggerCharacters: ['{'],
          provideCompletionItems: () => {
            const kind = monaco.languages.CompletionItemKind.Text;
            return {
              suggestions: this.suggestions?.map((item) => ({
                label: item.name,
                insertText: item.name,
                kind,
              })),
            };
          },
        });
      });
    },
    showPlaceholder() {
      if (!this.value) {
        document.querySelector(`#${this.placeHolderId}`).style.display =
          'inline-block';
      }
    },
    hidePlaceholder() {
      document.querySelector(`#${this.placeHolderId}`).style.display = 'none';
    },
  },
};
</script>

<style lang="scss">
@import './code-editor';
</style>
