<template>
  <div class="mx-auto WorkerSettings">
    <v-tabs v-model="workerTab" grow @change="changeTabs">
      <v-tab v-for="item in workerTabs" :key="item.key">
        {{ item }}
      </v-tab>
    </v-tabs>

    <div v-if="tab === 0" class="WorkerSettings__settings-tab">
      <v-text-field
        outlined
        dense
        :value="worker.name"
        @input="(val) => updateWorker('name', val)"
        label="Worker Name"
        class="mb-3"
      />

      <v-divider class="mx-n5 WorkerSettings__divider" />

      <section>
        <p class="text-h6 pt-4">Worker Inputs</p>

        <v-select
          class="WorkerSettings__creds mb-4"
          outlined
          dense
          :value="settings.authenticationId"
          @input="(val) => updateWorker('settings.authenticationId', val)"
          :items="authentications"
          item-text="name"
          item-value="id"
          label="Authentication"
          clearable
        ></v-select>
        <div v-if="!isPythonWorker">
          <v-switch
            label="Enable Recording"
            class="mb-3"
            :input-value="settings.isRecordingOn"
            @change="
              () =>
                updateWorker('settings.isRecordingOn', !settings.isRecordingOn)
            "
          ></v-switch>

          <v-text-field
            outlined
            dense
            label="Width"
            class="mb-3"
            hide-details="auto"
            :value="settings.widthSize"
            @change="(val) => updateWorker('settings.widthSize', val)"
          ></v-text-field>

          <v-text-field
            outlined
            dense
            label="Height"
            class="mb-3"
            hide-details="auto"
            :value="settings.heightSize"
            @change="(val) => updateWorker('settings.heightSize', val)"
          ></v-text-field>

          <v-alert type="warning" rounded="0" dense class="mx-n4">
            Inputs defined below are test values for the model. This needs to be
            replaced in Browser Automation.
          </v-alert>

          <v-row v-for="(item, i) in inputs" :key="i" class="mb-1" dense>
            <v-col>
              <v-text-field
                outlined
                dense
                :value="item.name"
                @input="(val) => updateWorker(`inputs[${i}].name`, val)"
                label="Name"
              />
            </v-col>
            <v-col>
              <v-text-field
                outlined
                dense
                label="Test Value"
                :value="item.value"
                @input="(val) => updateWorker(`inputs[${i}].value`, val)"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn icon @click="removeInput(i)">
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-btn
            text
            :disabled="disableAddInput"
            @click="addInput"
            color="primary"
          >
            + Add Input
          </v-btn>

          <div>
            <v-btn
              v-if="hasVideo()"
              @click="openVideoModal()"
              text
              color="primary"
              small
            >
              View Videos
            </v-btn>
          </div>
        </div>
      </section>

      <OutputModel :model="worker.model" />
    </div>

    <div class="WorkerSettings__result" v-else>
      <v-card outlined class="my-4 WorkerSettings__run-status" rounded="lg">
        <v-card-title class="WorkerSettings__result-logs-title">
          Logs
        </v-card-title>
        <div
          v-if="runningWorker"
          class="d-flex pa-4 align-center justify-center"
        >
          <v-progress-linear indeterminate color="success darken-2" />
        </div>
        <v-list flat v-else>
          <v-list-item v-for="(log, index) in workerLogs" :key="index">
            <v-list-item-icon>
              <v-icon :color="log.success ? 'success' : 'error'">
                {{
                  log.success
                    ? 'mdi-check-circle-outline'
                    : 'mdi-close-circle-outline'
                }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="WorkerSettings__result-log-item-title">
                <span class="WorkerSettings__result-log-event">
                  {{ index + 1 }}. {{ log.event }}
                </span>
                {{
                  log.success
                    ? 'was successful'
                    : `failed. Error: ${log.message}`
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <p class="WorkerSettings__result-header text-h6 my-2">Result</p>
      <IOViewer :action="action" />
    </div>

    <video-modal
      :dialog="videoDialog"
      :videoLinks="videoLinks"
      :toggleDialog="closeVideoModal"
    ></video-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import lodashSet from 'lodash/set';
import OutputModel from '@/components/output-model/output-model.vue';
import IOViewer from '@/components/io-viewer/io-viewer.vue';
import VideoModal from '@/components/modals/video-player/video-player.vue';

const { mapGetters: credentialsGetters } =
  createNamespacedHelpers('credentials');
const { mapGetters: workerGetters, mapMutations: workerMutations } =
  createNamespacedHelpers('workers');
const { mapActions: workflowDetailsActions } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'WorkerSettings',
  data() {
    return {
      tab: 0,
      videoDialog: false,
      videoLinks: [],
    };
  },
  components: {
    OutputModel,
    IOViewer,
    VideoModal,
  },
  computed: {
    ...workerGetters({
      worker: 'WORKER',
      settings: 'WORKER_SETTINGS',
      inputs: 'WORKER_INPUTS',
      action: 'WORKER_ACTION',
      workerLogs: 'WORKER_LOGS',
      runningWorker: 'RUNNING_WORKER',
    }),
    ...credentialsGetters({
      authentications: 'AUTHENTICATIONS',
    }),
    disableAddInput() {
      const inputs = this.inputs;
      if (!inputs) return true;
      if (!inputs[0]) return false;
      const lastInput = inputs[inputs.length - 1];
      return !lastInput?.name || !lastInput?.value;
    },
    workerTab: {
      get() {
        return this.tab;
      },
      set(value) {
        this.$emit('update:tab', value);
      },
    },
    isPythonWorker() {
      return this.worker?.type === 'python';
    },
    workerTabs() {
      if (this.isPythonWorker) {
        return ['Settings'];
      }
      return ['Settings', 'Output'];
    },
  },
  methods: {
    ...workerMutations({
      setInputs: 'SET_INPUTS',
      setWorker: 'SET_WORKER',
      setOutput: 'SET_OUTPUT',
    }),
    ...workflowDetailsActions(['getVideoLinks']),
    addInput() {
      const inputs = cloneDeep(this.inputs);
      inputs.push({ name: '', value: '' });
      this.setInputs(inputs);
    },
    removeInput(index) {
      this.inputs.splice(index, 1);
    },
    changeTabs(value) {
      this.tab = value;
    },
    updateWorker(prop, value) {
      const worker = cloneDeep(this.worker);
      lodashSet(worker, prop, value);
      this.setWorker(worker);
    },
    async openVideoModal() {
      const isRecording = this.settings?.isRecordingOn;
      if (isRecording) {
        const taskId = this.worker.taskId;
        const storageId = 'single-execution';
        this.videoLinks = await this.getVideoLinks({ storageId, taskId });
      }
      this.videoDialog = !this.videoDialog;
    },
    closeVideoModal() {
      this.videoDialog = !this.videoDialog;
    },
    hasVideo() {
      return this.settings?.isRecordingOn && this.worker.taskId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './worker-settings';
</style>
