import { isArray, isObject } from 'lodash';
import uuidv4 from './uuid_generator';

export const shTypes = {
  ARRAY: 'array',
  OBJECT: 'object',
};

export function getModelFromSql(objName, model) {
  const singular = objName.endsWith('s')
    ? objName.substring(0, objName.length - 1)
    : objName;
  const props = Object.keys(model).map(
    (key) => new ShPrimitive(uuidv4(), key, model[key])
  );

  const obj = new ShObject(uuidv4(), singular, props);
  return new ShArray(uuidv4(), objName, obj);
}

export function getModel(model, key) {
  if (isArray(model)) {
    return new ShArray(uuidv4(), key || 'Array', getModel(model[0]));
  } else if (isObject(model)) {
    const props = Object.keys(model).map((key) => {
      const value = model[key];
      return getModel(value, key);
    });
    return new ShObject(uuidv4(), key || 'Object', props);
  } else {
    return new ShPrimitive(
      uuidv4(),
      key || 'element',
      model === null ? 'null' : model
    );
  }
}

export function getModelType(model, key) {
  if (isArray(model)) {
    return new ShArray(uuidv4(), key || 'Array', getModelType(model[0]));
  } else if (isObject(model)) {
    const props = Object.keys(model).map((key) => {
      const value = model[key];
      return getModelType(value, key);
    });
    return new ShObject(uuidv4(), key || 'Object', props);
  } else {
    return new ShPrimitive(
        uuidv4(),
        key || 'element',
        model === null ? 'null' : typeof model
    );
  }
}

export function cloneModel(newName, sourceModel) {
  return {
    ...sourceModel,
    name: newName,
    uid: uuidv4(),
  };
}

export function genUid() {
  return uuidv4();
}

export const getOutputTree = (output) => {
  if (output != null) {
    if (typeof output === 'object') {
      return Object.keys(output).map((key) => {
        if (
          // eslint-disable-next-line
          output.hasOwnProperty(key) &&
          output[key] != null &&
          (typeof output[key] === 'object' || Array.isArray(output[key]))
        ) {
          return {
            name: key,
            children: getOutputTree(output[key]),
          };
        } else {
          if (
            typeof output[key] === 'string' &&
            output[key].includes(';base64')
          ) {
            return {
              image: output[key],
              name: key + ': ' + output[key].substring(0, 30) + '...',
            };
          }
          return {
            name: key + ': ' + output[key],
          };
        }
      });
    } else if (Array.isArray(output)) {
      return output.map((el) => getOutputTree(el));
    }
  } else {
    return 'no output';
  }
};

class ShArray {
  // name is optional
  constructor(uid, name, subtype) {
    this.uid = uid || uuidv4();
    this.name = name || `Array [${subtype.name}]`;
    this.type = shTypes.ARRAY;
    this.value = subtype;
  }
  get typeForDisplay() {
    return `Array [${this.name}]`;
  }
}

class ShObject {
  // props - array of Prop
  constructor(uid, name, props) {
    this.uid = uid || uuidv4();
    this.name = name;
    this.type = shTypes.OBJECT;
    this.value = props;
  }
}

class ShPrimitive {
  constructor(uid, name, type) {
    this.uid = uid || uuidv4();
    this.name = name;
    this.type = type;
    this.value = name;
  }
}
