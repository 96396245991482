<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="900"
    :value="dialog"
    @click:outside="toggleDialog()"
    content-class="VideoModal"
    scrollable>
    <v-card
      outlined
      class="VideoModal__card"
      height="500">
      <v-card-title class="VideoModal__title">
        <v-row>
          <v-col
            cols="auto"
            class="mr-auto">
            <p class="ma-0">Videos</p>
          </v-col>
          <v-col
            cols="auto"
            class="px-0">
            <v-btn
              icon
              @click="close">
              <v-icon
                small
                color="grey">
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="VideoModal__content">
        <v-row>
          <v-col
            :cols="4"
            class="VideoModal__image-list">
            <v-card
              v-for="(link, index) in videoLinks"
              :key="index"
              class="VideoModal__video-list-video mb-2"
              outlined>
              <v-card-title
                @click="handleVideoSelect(index)"
                v-if="link"
                class="text-body-2 pa-2 VideoModal__video-list-video-title">
                {{ 'Video ' + index }}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col
            :cols="8"
            class="VideoModal__video">
            <v-card
              outlined
              height="397">
              <video
                :key="selectedVideoLink"
                width="550"
                height="397"
                controls>
                <source
                  
                  :src="selectedVideoLink"
                  type="video/webm" />
              </video>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VideoModal",
  props: ["dialog", "toggleDialog", "videoLinks"],
  data() {
    return {
      selectedVideoLink: this.videoLinks[0],
    };
  },
  methods: {
    close() {
      this.toggleDialog();
    },
    handleVideoSelect(index) {
      this.selectedVideoLink = this.videoLinks[index];
    },
  },
};
</script>

<style lang="scss">
@import "./video-player.scss";
</style>
