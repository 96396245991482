<template>
  <div class="Worker">
    <Loader text="Loading Worker" v-if="!worker || !worker?.type" />
    <div v-else>
      <header class="Worker__header">
        <div
          class="Worker__header-container d-flex justify-space-between align-center"
        >
          <div class="d-flex align-center">
            <p class="Worker__header-title">
              <router-link
                to="/workers"
                tag="span"
                class="Worker__header-title-link"
              >
                Workers
              </router-link>
              <v-icon small class="Worker__header-title-icon">
                mdi-chevron-right
              </v-icon>
              <span class="Worker__header-title-name" v-if="worker.name">
                {{ worker.name }}
              </span>
              <span class="Worker__header-title-no-name" v-else>
                Worker name here
              </span>
            </p>
          </div>

          <div class="d-flex justify-space-between">
            <v-btn
              v-if="isPlaywrightWorker"
              color="success"
              :disabled="runningWorker || editWorkerLoading"
              @click="testRun"
              :loading="runningWorker"
              elevation="0"
              outlined
            >
              <v-icon class="mr-3" right>mdi-bug-check</v-icon> Run Worker
            </v-btn>

            <div
              class="Worker__header-button-divider"
              v-if="isPlaywrightWorker"
            ></div>

            <v-btn
              color="primary"
              elevation="0"
              :disabled="editWorkerLoading"
              @click="save"
              :loading="editWorkerLoading"
            >
              Save Worker
            </v-btn>
          </div>
        </div>
      </header>

      <div class="Worker__main-content">
        <v-row>
          <v-col cols="12" lg="8">
            <CodeEditor
              :value="settings.script"
              @input="(val) => editWorker('settings.script', val)"
              :language="lang"
              class="mb-3"
              :suggestions="availableInputFields"
              :placeholder="''"
              :height="'1000px'"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-card :width="370" class="SideSettings" :elevation="1">
              <WorkerSettings />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import lodashSet from 'lodash/set';

import { getModel } from '@/util/actionsModels';
import CodeEditor from '@/components/code-editor/code-editor.vue';
import Loader from '@/components/loader/loader.vue';
import WorkerSettings from './worker-settings/worker-settings.vue';
import { extendInputWithAuthentication } from '@/util/extendInputWithCreds';

const { mapActions: sharedActions } = createNamespacedHelpers('shared');
const {
  mapGetters: workersGetters,
  mapActions: workersActions,
  mapMutations: workerMutations,
} = createNamespacedHelpers('workers');
const { mapActions: credentialsActions, mapGetters: credentialsGetters } =
  createNamespacedHelpers('credentials');
const { mapActions: workflowDetailsActions } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'Worker',
  components: {
    CodeEditor,
    WorkerSettings,
    Loader,
  },
  data() {
    return {
      crumbs: [
        {
          text: 'Workers',
          disabled: false,
          exact: true,
          to: { name: 'Workers' },
        },
        {
          text: 'Worker',
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...workersGetters({
      worker: 'WORKER',
      runningWorker: 'RUNNING_WORKER',
      fetchWorkerLoading: 'FETCH_WORKER_LOADING',
      editWorkerLoading: 'EDIT_WORKER_LOADING',
      settings: 'WORKER_SETTINGS',
      inputs: 'WORKER_INPUTS',
    }),
    ...credentialsGetters({
      authentications: 'AUTHENTICATIONS',
    }),
    workerType() {
      const props = {
        python: {
          lang: 'python',
          action: 'python_worker',
          isServerless: false,
        },
        playwright: {
          lang: 'javascript',
          action: 'short_browser_auto_playwright',
          isServerless: true,
        },
      };

      return props[this.worker.type];
    },
    isPythonWorker() {
      return this.worker?.type === 'python';
    },
    isPlaywrightWorker() {
      return this.worker?.type === 'playwright';
    },
    lang() {
      if (this.isPythonWorker) {
        return 'python';
      }
      return 'javascript';
    },
    authenticationItem() {
      return this.authentications.find(
        (item) => item.id === this.settings.authenticationId
      );
    },
    availableInputFields() {
      if (!this.authenticationItem) return this.inputs;
      return extendInputWithAuthentication(
        this.inputs,
        this.authenticationItem
      );
    },
  },
  mounted() {
    this.toggleHeader(false);
    this.fetchAuthentications();
    this.setInputs([]);
    const { workerId } = this.$route.params;
    if (workerId !== 'new') {
      this.fetchWorker(workerId);
    }
  },
  methods: {
    ...sharedActions(['toggleHeader']),
    ...workerMutations({
      setInputs: 'SET_INPUTS',
      setWorker: 'SET_WORKER',
      setRunningWorker: 'SET_RUNNING_WORKER',
      setOutput: 'SET_OUTPUT',
      setWorkerLogs: 'SET_WORKER_LOGS',
    }),
    ...workersActions(['fetchWorker', 'updateWorker', 'clearWorker']),
    ...credentialsActions(['fetchAuthentications']),
    ...workflowDetailsActions(['executeSingleAction']),
    editWorker(prop, value) {
      const worker = cloneDeep(this.worker);
      lodashSet(worker, prop, value);
      this.setWorker(worker);
    },
    async testRun() {
      this.setRunningWorker(true);
      this.setOutput({ model: {}, action: {} });
      this.setWorkerLogs([]);
      const inputs = {};
      this.inputs.forEach((input) => {
        inputs[input.name] = input.value;
      });
      const options = {
        creds: this.settings.authenticationId,
        data: { ...this.settings, automation: this.settings.script },
        action: this.workerType.action,
        input: inputs,
        isServerless: this.workerType.isServerless,
      };
      const action = { input: inputs };
      try {
        const data = await this.executeSingleAction(options);
        const model = getModel(data.model);
        action.output = data.data;
        this.setOutput({ model, action, taskId: data.taskId });
        this.setWorkerLogs(data.WorkerLogs);
      } catch (error) {
        this.setOutput({ action });
        this.setWorkerLogs(error.WorkerLogs);
      } finally {
        this.setRunningWorker(false);
      }
    },
    save() {
      return this.updateWorker(this.worker);
    },
  },
  beforeDestroy() {
    this.clearWorker();
    this.toggleHeader(true);
  },
};
</script>

<style lang="scss">
@import './worker';
</style>
