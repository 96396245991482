<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="900"
    :value="dialog"
    @click:outside="toggleDialog()"
    content-class="ImageModal"
    scrollable
  >
    <v-card outlined class="ImageModal__card" height="500">
      <v-card-title class="ImageModal__title">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            <p class="ma-0">
              Screenshots
            </p>
          </v-col>
          <v-col cols="auto" class="px-0">
            <v-btn icon @click="close">
              <v-icon small color="grey"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="ImageModal__content">
        <v-row>
          <v-col :cols="4" class="ImageModal__image-list">
            <v-card
              v-for="(image, index) in images"
              :key="index"
              class="ImageModal__image-list-image mb-2"
              outlined
            >
              <v-img
                :src="image.file ? image.file : image"
                @click="handleImageSelect(index)"
                class="align-end"
              >
                <v-card-title
                  v-if="image.name"
                  class="text-body-2 pa-2 ImageModal__image-list-image-title"
                >
                  {{image.name}}
                </v-card-title>
              </v-img>
            </v-card>
          </v-col>
          <v-col :cols="8" class="ImageModal__image">
            <v-card outlined height="397">
              <v-carousel
                v-model="selectedImage"
                :continuous="false"
                :show-arrows="false"
                :hide-delimiters="true"
                height="397"
              >
                <v-carousel-item
                  v-for="(image, index) in images"
                  :key="index"
                  :src="image.file ? image.file : image"
                  contain
                  max-height="397"
                />
              </v-carousel>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImageModal",
  props: ["dialog", "toggleDialog", "images"],
  data() {
    return {
      selectedImage: 0,
    };
  },
  methods: {
    close() {
      this.toggleDialog();
    },
    handleImageSelect(index) {
      this.selectedImage = index;
    },
  },
};
</script>

<style lang="scss">
@import "./image-modal";
</style>
