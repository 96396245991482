<template>
  <div class="Loader">
    <div>
      <v-progress-circular
        :size="150"
        :width="3"
        color="primary"
        indeterminate
      />
      <p class="text-center py-4">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    text: {
      type: String,
      default: "Loading...",
    },
  },
};
</script>


<style lang="scss" scoped>
.Loader {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
</style>
