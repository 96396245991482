import uuidv4 from "./uuid_generator";

export function extendInputWithAuthentication(inputs, authentication) {
  const availableFields = [...inputs];
  authentication?.credentials.forEach((item) => {
    availableFields.push({
      name: `credentials.${item.name}`,
      uid: uuidv4(),
    });
  });

  return availableFields;
}
