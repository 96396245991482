<template>
  <div>
    <div>
      <div>Input:</div>
      <json-viewer
        v-if="!isNullOrUndefined(data.input)"
        :value="data.input"
        :expand-depth="0"
        boxed
        sort
        copyable
      ></json-viewer>
      <div v-else class="pl-5">Null</div>
    </div>
    <div class="mt-4">
      <span>Output:</span>
      <div v-if="data.output" class="pb-1 d-flex justify-space-between">
        <v-btn
          v-if="hasScreenshots(data.output)"
          @click="openImageModal(data.output)"
          text
          color="primary"
          small
        >
          View Screenshots
        </v-btn>
        <div>
          <v-btn
            v-if="hasVideo(data)"
            @click="openVideoModal(data)"
            text
            color="primary"
            small
          >
            View Videos
          </v-btn>
        </div>
        <a
          :download="data.output.File.name"
          :href="data.output.File.file"
          title="Download pdf document"
          v-if="hasPDF(data.output)"
        >
          <v-btn text color="primary" small> Download pdf document </v-btn>
        </a>
        <div>
          <a
            :download="data.output.data.File.name"
            :href="data.output.data.File.file"
            title="Download document"
            v-if="hasPDF(data.output.data)"
          >
            <v-btn text color="primary" small> Download file </v-btn>
          </a>
        </div>
      </div>
      <json-viewer
        v-if="!isNullOrUndefined(data.output)"
        :value="data.output"
        :expand-depth="0"
        boxed
        sort
        copyable
      ></json-viewer>
      <div v-else class="pl-5">Null</div>
    </div>
    <image-modal
      :dialog="imageDialog"
      :images="images"
      :toggleDialog="openImageModal"
    ></image-modal>
    <video-modal
      :dialog="videoDialog"
      :videoLinks="videoLinks"
      :toggleDialog="closeVideoModal"
    ></video-modal>
    <div v-if="hasChildren(data)" class="mt-4">
      <span>Child Workflows:</span>
      <div v-for="(children,index) in data.childrenStorages" v-bind:key="index" class="mt-2">
        <a @click.stop="goToChildren(children)">{{children.AutomationWorkflow.name}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';
import 'vue-json-viewer/style.css';
import { createNamespacedHelpers } from 'vuex';
import ImageModal from '@/components/modals/image-modal/image-modal.vue';
import VideoModal from '@/components/modals/video-player/video-player.vue';
const { mapActions: workflowDetailsActions } =
  createNamespacedHelpers('workflows/details');

const {
  mapMutations: workflowHistoryMutation,
} = createNamespacedHelpers('workflows/history');


export default {
  name: 'InputOutputViewer',
  components: {
    JsonViewer,
    ImageModal,
    VideoModal,
  },
  data() {
    return {
      data: null,
      images: [],
      videoLinks: [],
      imageDialog: false,
      videoDialog: false,
    };
  },
  props: {
    action: {
      required: true,
    },
    isServerless: {
      required: false,
    },
  },
  async created() {
    this.data = this.action;
    if (this.isServerless) {
      this.loadData();
    }
  },
  watch: {
    action: {
      handler() {
        if (this.isServerless && !this.data.output) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...workflowDetailsActions(['getVideoLinks', 'getWorkflowTask']),
    ...workflowHistoryMutation({
      setWorkflowHistoryItem: 'SET_WORKFLOW_HISTORY_ITEM'
    }),
    openImageModal(data) {
      if (data) {
        this.images = data.screenshots;
      }
      this.imageDialog = !this.imageDialog;
    },
    async openVideoModal(action) {
      const isRecording = action?.action_settings?.isRecordingOn;
      if (isRecording) {
        const taskId = action.id;
        const storageId = action.parent_storage_id;
        this.videoLinks = await this.getVideoLinks({ storageId, taskId });
      }
      if (this.videoLinks.length > 0) {
        this.videoDialog = !this.videoDialog;
      }
    },
    async loadData() {
      const taskId = this.action.id;
      const storageId = this.action.parent_storage_id;
      const data = await this.getWorkflowTask({ storageId, taskId });
      this.data = data;
    },
    closeVideoModal() {
      this.videoDialog = !this.videoDialog;
    },
    hasScreenshots(data) {
      return data && data.screenshots && data.screenshots.length;
    },
    hasVideo(action) {
      const isRecording = action?.action_settings?.isRecordingOn;
      return isRecording;
    },
    hasPDF(data) {
      return (
        data?.File?.file &&
        typeof data?.File?.file === 'string' &&
        data?.File?.file?.startsWith('data:application/pdf;base64,')
      );
    },
    hasChildren(data) {
      return data?.childrenStorages?.length > 0;
    },
    isNullOrUndefined(data) {
      if (data === null || data === undefined) {
        return true;
      }
      return false;
    },
    goToChildren(children) {
      const ref = this.$router.resolve({
        path: `/workflows/${children.workflow_id}/history?customer_id=${children.customer_id}&storageId=${children.id}`,
      });
      window.open(ref.href, '_blank');
    },
  },
};
</script>
